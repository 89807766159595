import { CommonContainer, InputHeader } from './CommonComponents';
import CommonPostcodeInput from './CommonPostcodeInput';
import React from 'react';
import styled from 'styled-components';
import reading from '../images/reading.svg';

export const TopBarContainer = styled(CommonContainer)`
  margin: 24px 0 12px;
`;

export const TopInputWrapper = styled.div`
  //align-self: flex-start;
`;

const ReadingIcon = styled.img`
  width: 42px;
  margin: 12px;
`;

export const TopBar = () => {
  return (
    <TopBarContainer>
      <ReadingIcon src={reading} />
      <TopInputWrapper>
        <InputHeader>Your postcode</InputHeader>
        <CommonPostcodeInput placeholder={'e.g. N1 8LN'} idx={0} />
      </TopInputWrapper>
    </TopBarContainer>
  );
};

export default TopBar;
