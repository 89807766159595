import { createContext } from 'react';
import { Postcode } from '../types';

type PostcodeContextType = {
  postcodes?: Postcode[];
  updatePostcodes: (search: Postcode, idx: number) => void;
};

const PostcodesContext = createContext<PostcodeContextType>({
  updatePostcodes: () => Promise.resolve(),
});

export default PostcodesContext;
