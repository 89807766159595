import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Loading from './components/Loading';
import MapContainer from './components/MapContainer';
import { GoogleAPI, GoogleApiWrapper } from 'google-maps-react';
import HalfwayPintButton from './components/HalfwayPintButton';

import { LatLng, LocationPoint, Postcode, PostcodesIOInfo } from './types';
import PostcodesContext from './context/PostcodesContext';
import TopBar from './components/TopBar';
import BottomBar from './components/BottomBar';
import { calculateMidPoint } from './data/calculateHalfway';
import { getPostcodeInfo } from './components/CommonPostcodeInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  box-sizing: border-box;

  border-top: 10px solid var(--yellow);
  border-bottom: 35px solid var(--yellow);
  //border-bottom: 10px solid var(--yellow);

  z-index: 2;
`;

const FullScreenMap: any = styled.div`
  position: fixed;

  opacity: ${({ opaque }: any) => (opaque ? 0.3 : 1)};
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
`;

const SearchAgain = styled.button`
  position: fixed;

  margin: auto;

  bottom: 20px;

  z-index: 2;

  color: var(--primary);
  background-color: var(--yellow);
  border: 3px solid var(--primary);
  padding: 16px;

  border-radius: 12px;
  font-size: 24px;
  cursor: pointer;
`;

const About = styled.a`
  font-size: 14px;
  position: absolute;
  bottom: 10px;
`;

type AppProps = {
  google: GoogleAPI;
};

export const App: React.FC<AppProps> = ({ google }: AppProps) => {
  const [postcodes, setPostcodes] = useState<Postcode[]>([]);
  const [mapMidPoint, setMapMidPoint] = useState<LatLng>();

  async function onSubmit() {
    const postcodeCheatOne: any = document.getElementById('0');
    console.log({ postcodeCheatOne });
    const postcodeCheatTwo: any = document.getElementById('1');
    console.log({ postcodeCheatTwo });

    const postcodesListRaw = [postcodeCheatOne.value, postcodeCheatTwo.value];

    let postcodesList = [];
    try {
      postcodesList = await Promise.all(
        postcodesListRaw.map(async (v) => {
          const postcodeInfo: PostcodesIOInfo = await getPostcodeInfo(v);
          return {
            postcode: postcodeInfo.postcode,
            coordinates: { latitude: postcodeInfo.latitude, longitude: postcodeInfo.longitude },
          };
        })
      );
    } catch (e) {
      return;
    }

    console.log({ postcodesList });

    if (postcodesList.length < 2) {
      return;
    }

    const postcodesToLocationPoint: LocationPoint[] = postcodesList.map((postcode) => {
      return postcode.coordinates;
    });

    const midPoint = calculateMidPoint(postcodesToLocationPoint);
    const alternativeMidPoint = {
      latitude: (postcodesToLocationPoint[0].latitude + postcodesToLocationPoint[1].latitude) / 2,
      longitude: (postcodesToLocationPoint[0].longitude + postcodesToLocationPoint[1].longitude) / 2,
    };

    console.log({ midPoint, alternativeMidPoint });

    setMapMidPoint({ lat: midPoint.latitude, lng: midPoint.longitude });
  }

  function updatePostcodes(postcode: Postcode, idx: number) {
    console.log('updatePostcodes', postcode);
    const newPostcodes = [...postcodes];
    if (idx === newPostcodes.length) {
      newPostcodes.push(postcode);
    } else {
      newPostcodes[idx] = postcode;
    }
    setPostcodes(newPostcodes);
  }

  return (
    <Container>
      <Helmet>
        <title>Halfway Pint</title>
        <meta name="description" content="Halfway place to meet someone and have a pint" />
      </Helmet>
      {!mapMidPoint && (
        <PostcodesContext.Provider value={{ postcodes, updatePostcodes }}>
          <InnerContainer>
            <TopBar />
            <BottomBar />
            <HalfwayPintButton onSubmit={onSubmit} />
            <About href={'https://jvmartins.com'} target="_blank">
              About the creator
            </About>
          </InnerContainer>
        </PostcodesContext.Provider>
      )}

      <FullScreenMap opaque={!mapMidPoint}>
        {mapMidPoint ? (
          <MapContainer google={google} position={mapMidPoint} displayMarkers={true} zoom={15} />
        ) : (
          <MapContainer google={google} />
        )}
      </FullScreenMap>

      {mapMidPoint && (
        <SearchAgain
          onClick={() => {
            setMapMidPoint(undefined);
            setPostcodes([]);
          }}
        >
          Search Again
        </SearchAgain>
      )}
    </Container>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAAI3pc48jej-SjNBplwO82Ay-b6hDZB7Q',
  libraries: ['places'],
  LoadingContainer: () => <Loading />,
})(App);
