import React from 'react';
import styled from 'styled-components';

export const InputHeader = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`;

export const CommonInput: any = styled.input`
  box-sizing: border-box;
  background-color: transparent;
  font-family: 'Orelega One', cursive;
  color: var(--primary);

  text-align: ${({ orientation }: any) => orientation};
  text-transform: uppercase;

  border: none;
  margin: 0;
  padding: 4px;
  border-radius: 5px;

  max-width: 180px;

  font-size: 22px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    //color: white;
    color: var(--secondary);
    opacity: 0.5; /* Firefox */
    font-family: 'Orelega One', cursive;
    text-transform: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(256, 256, 256, 0.75) inset !important;
  }
`;

export const CommonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 85%;
  max-width: 300px;
  border-radius: 40px;
  height: 70px;
  border: 2px solid var(--primary);
  box-shadow: 2px 5px 5px -2px grey;
  padding: 4px 0;

  background-color: rgba(255, 255, 255, 0.75);
`;
