import React, { useState } from 'react';
import { Circle, GoogleAPI, Map, Marker } from 'google-maps-react';

import beerIcon from '../images/small-icon.png';

import data from '../data/simplified-data.json';

import { LatLng, Place } from '../types';
import styled from 'styled-components';

type MapContainerProps = {
  google: GoogleAPI;
  position?: LatLng;
  zoom?: number;
  displayMarkers?: boolean;
};

const initialCenter: LatLng = {
  lat: 51.4934124,
  lng: -0.1337969,
};

const minZoom = 12;

const StyledModal = styled.div`
  border: 3px solid var(--primary);
  background-color: white;
  width: 86%;

  margin: auto;
  position: sticky;
  top: 40px;
  max-width: 500px;
  border-radius: 10px;

  padding: 20px 0px;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-evenly;

  font-size: 16px;
  margin-top: 20px;
`;

const Link = styled.a`
  display: inline-block;
  text-decoration: underline;

  margin: 5px 0;
`;

const ModalTitle = styled.h2`
  padding: 0 20px;

  margin: 5px 0;
  margin-right: 30px;

  max-width: 90%;

  letter-spacing: 0.5px;
  font-size: 28px;
`;

const ModalSubtitle = styled.h4`
  padding: 0 20px;

  margin: 10px 0 20px;
  font-weight: normal;
`;

const Info = styled.div`
  padding: 0 20px;
  //margin: 5px 0;
`;

const XButton = styled.div`
  font-weight: bold;
  top: 16px;
  right: 20px;
  font-size: 32px;
  position: absolute;
  cursor: pointer;
`;

const ModalImage: any = styled.div`
  width: 100%;

  background-image: url(${({ src }: any) => src});
  background-position: center;
  height: 220px;
  background-size: cover;

  margin-bottom: 12px;

  @media (max-height: 700px) {
    height: 160px;
  }

  @media (max-height: 568px) {
    height: 120px;
  }
`;

const NoSmallPhones = styled.div`
  @media (max-height: 600px) {
    display: none;
  }
`;

const Modal = ({ place, clearPlace }: { place: Place; clearPlace: () => void }) => {
  return (
    <StyledModal>
      <ModalTitle>{place.name}</ModalTitle>
      <ModalSubtitle>{place.address}</ModalSubtitle>
      {place.onePhoto ? <ModalImage src={place.onePhoto} /> : null}

      <Info>🌳 Outside area: {place.outsideType}</Info>
      {place.rating ? <Info>⭐ ️{place.rating} (Google rating)</Info> : null}
      <InfoBox>
        <NoSmallPhones>
          🌐 <Link href={place.website}> website</Link>
        </NoSmallPhones>
        <div>
          📞 <Link href={`tel:${place.phoneNumber}`}> phone</Link>
        </div>
        <div>
          🗺 <Link href={place.url}> directions</Link>
        </div>
      </InfoBox>
      <XButton onClick={clearPlace}>X</XButton>
    </StyledModal>
  );
};

export default ({ google, position, zoom = minZoom, displayMarkers = false }: MapContainerProps) => {
  const [place, setPlace] = useState<Place>();

  function renderMarkers() {
    return data.map((markerPlace: any, idx: number) => (
      <Marker
        key={idx}
        icon={beerIcon}
        position={{
          lat: markerPlace.location.lat,
          lng: markerPlace.location.lng,
        }}
        onClick={() => openModal(markerPlace)}
      />
    ));
  }

  function renderCircle() {
    console.log({ position });
    return <Circle defaultCenter={position} radius={2000} options={{}} />;
  }

  function openModal(place: Place) {
    setPlace(place);
  }

  // @ts-ignore
  return (
    <Map
      google={google}
      initialCenter={position || initialCenter}
      // @ts-ignore
      zoom={zoom}
      zoomControl={false}
      center={position || initialCenter}
      minZoom={minZoom}
      mapTypeControl={false}
      scaleControl={false}
      streetViewControl={false}
      rotateControl={false}
      fullscreenControl={false}
      onClick={() => setPlace(undefined)}
    >
      {displayMarkers ? renderMarkers() : null}
      {/*{displayMarkers ? renderCircle() : null}*/}
      {place && displayMarkers ? <Modal place={place} clearPlace={() => setPlace(undefined)} /> : null}
    </Map>
  );
};
