import { LocationPoint } from '../types';

export function calculateMidPoint(locations: LocationPoint[]): LocationPoint {
  if (!locations.length) {
    throw new Error('No locations to calculate midPoint');
  }

  if (locations.length === 1) {
    return locations[0];
  }

  const totalLocations = locations.length;

  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  for (let i = 0; i < totalLocations; i++) {
    const lat = (locations[i].latitude * Math.PI) / 180;
    const lon = (locations[i].longitude * Math.PI) / 180;

    X += Math.cos(lat) * Math.cos(lon);
    Y += Math.cos(lat) * Math.sin(lon);
    Z += Math.sin(lat);
  }

  X /= totalLocations;
  Y /= totalLocations;
  Z /= totalLocations;

  const lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  const lat = Math.atan2(Z, hyp);

  return {
    latitude: (lat * 180) / Math.PI,
    longitude: (lon * 180) / Math.PI,
  };
}
