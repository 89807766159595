import React, { useContext, useState } from 'react';
import { CommonInput } from './CommonComponents';
import PostcodesContext from '../context/PostcodesContext';
import { PostcodesIOResponse, PostcodesIOInfo } from '../types';
import styled from 'styled-components';

export async function getPostcodeInfo(postcode: string): Promise<PostcodesIOInfo> {
  const postcodesIoResponse = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
  if (postcodesIoResponse.status !== 200) {
    throw new Error('Not recognised');
  }

  const postcodesIoInfo: PostcodesIOResponse = await postcodesIoResponse.json();
  return postcodesIoInfo.result;
}

type CommonPostcodeInputProps = {
  idx: number;
  placeholder: string;
  orientation?: 'left' | 'right';
};

const Invalid = styled.div`
  color: #ef4d4d;
  font-size: 12px;
`;

export const CommonPostcodeInput = ({ idx, placeholder, orientation = 'left' }: CommonPostcodeInputProps) => {
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const { updatePostcodes } = useContext(PostcodesContext);

  async function update() {
    try {
      const postcodeInfo: PostcodesIOInfo = await getPostcodeInfo(value);
      updatePostcodes(
        {
          postcode: postcodeInfo.postcode,
          coordinates: { latitude: postcodeInfo.latitude, longitude: postcodeInfo.longitude },
        },
        idx
      );
      setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  }

  return (
    <>
      <CommonInput
        id={idx}
        name="postcode"
        placeholder={placeholder}
        value={value}
        orientation={orientation}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onBlur={update}
      />
      {!isValid ? <Invalid>Not a valid postcode</Invalid> : <div style={{ height: '4px' }} />}
    </>
  );
};

export default CommonPostcodeInput;
