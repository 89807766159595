import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  margin-top: 120px;
`;

export default function Loading() {
  return (
    <LoadingContainer>
      <span>Loading</span>
    </LoadingContainer>
  );
}
