import styled, { keyframes } from 'styled-components';
import beerIcon from '../images/icon.png';
import React from 'react';

export const BeerIcon = styled.img`
  width: 50px;
  margin: 24px;
`;

const pulse = keyframes`
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44,0.5);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.5);
    }
    70% {
      -moz-box-shadow: 0 0 0 28px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 28px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
`;

const HalfwayPintButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 3px solid var(--primary);

  border-radius: 16px;

  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: ${pulse} 2s infinite;
  cursor: pointer;

  background-color: white;
`;

const FindButton = styled.button`
  color: var(--primary);
  background-color: var(--yellow);
  border: none;
  border-top: 3px solid var(--primary);
  padding: 16px;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  font-size: 24px;

  cursor: pointer;
`;

const AnotherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Orelega One', cursive;
`;

type HalfwayPintButtonProps = {
  onSubmit: () => void;
};

const Info = styled.div`
  width: 250px;
  margin-bottom: 12px;
  text-align: center;
`;

export default ({ onSubmit }: HalfwayPintButtonProps) => {
  return (
    <AnotherContainer>
      <Info>From 12th April you can have a drink in a pub with an outside area</Info>
      <HalfwayPintButton onClick={onSubmit}>
        <BeerIcon src={beerIcon} />
        <FindButton>Find a Halfway Pint</FindButton>
      </HalfwayPintButton>
      <div style={{ marginTop: '8px' }}>And meet halfway in London 🇬🇧</div>
    </AnotherContainer>
  );
};
