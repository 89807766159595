import styled from 'styled-components';
import React from 'react';
import { CommonContainer, InputHeader } from './CommonComponents';
import CommonPostcodeInput from './CommonPostcodeInput';
import sitting from '../images/sitting.svg';

const BottomBarContainer = styled(CommonContainer)`
  margin-bottom: 80px;

  @media (max-height: 700px) {
    margin-bottom: 30px;
  }

  @media (max-height: 900px) {
    margin-bottom: 55px;
  }
`;

const BottomInputWrapper = styled.div`
  //text-align: right;
`;

const SittingIcon = styled.img`
  width: 42px;
  margin: 12px;
`;

export const BottomBar = () => (
  <BottomBarContainer>
    <SittingIcon src={sitting} />
    <BottomInputWrapper>
      <InputHeader>Your friend's postcode</InputHeader>
      <CommonPostcodeInput placeholder={'e.g. SW19 4RJ'} idx={1} />
    </BottomInputWrapper>
  </BottomBarContainer>
);

export default BottomBar;
